.icon-hover-wrap {
    transition: 0.2s;
}

.icon-hover-wrap:hover {
    cursor: pointer;
}

.hover-cursor:hover {
    cursor: pointer;
}

.remove-part-icon {
    width: 20px;
    height: 20px;
    font-size: 20px;

    color: #555b6d;

    transition: 0.2s;
}

.remove-part-icon:hover {
    cursor: pointer;
    color: #F46A6A !important;
}  

.remove-section-link > div {
    transition: 0.2s;
}

.remove-section-link:hover > div {
    cursor: pointer;
    color: #F46A6A !important;
}

.card-img-300 {
    max-width: 300px;

}

@media (max-width: 1199.98px) { 
    .card-img-300 {
        max-width: 100%;
    }
  }

.templates-tag-list {
    display: flex;
    gap: 6px;
}

.templates-tag {
    padding: 6px;
    background-color: #e6e6e6;
    font-size: 11px;
}

.templates-link {
    color: #495057;
}

.templates-table-controls {
    display: flex;
    gap: 8px;
}

.card-split-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.form-feedback-text {
    color: #f46a6a;
    font-size: 10.4px;
    margin-top: 4px;
}

.select-design-tabs-flex {
    margin-bottom: 10px;
    gap: 6px;
}

.select-design-templates-flex {
    gap: 30px;
}

.select-design-template-content-bottom {
    display: flex;
    justify-content: space-between;

    @media (max-width: 600px) { 
        flex-direction: column;
      }
}

.cardimg-template-preview {
    @media (min-width: 1200px) { 
        max-width: none;
        width: auto;
        max-height: 400px;
      }
}

.development-progress-text-box {
    position: absolute;
    transform: translate(-50%, -50%) !important;
    text-align: center;
    top: 45px;
    max-width: 80px;
    font-size: 11px;

    @media (min-width: 576px) { 
        max-width: 100px;
        font-size: 13px;
      }
}

.text-instead-input {
    padding-top: calc(0.47rem + var(--bs-border-width));
    padding-bottom: calc(0.47rem + var(--bs-border-width));
}

.text-muted {
    display: block;
    margin-top: 2px;
}

// Card project
.card-project-image-col {
    padding-right: 10px;

    @media (min-width: 1400px) { 
      padding-right: 20px;
    }
}

.card-project-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 15px;
    row-gap: 0px;
    
    @media (min-width: 576px) { 
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
    }
}

.card-project-item {
    
}

.requests-small-table-row:hover {
    cursor: pointer;
}

.requests-small-table-cell {
    vertical-align: middle;
    padding: 0.75rem 0.3rem !important;
}

.requests-table {
    table-layout: fixed;
}

.requests-table-cell {
    vertical-align: middle;
}

.request-detail-label {
    margin: 0;
    padding: 0 8px;
}
