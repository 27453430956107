.logo-thumbnail-wrap {
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 10px 0 0 0;
}

.logo-thumbnail {
  display: block;
  max-height: 150px;
  max-width: 200px;
}

.logo-thumbnail-wrap:hover {
  cursor: pointer;
  filter: brightness(85%);
} 

.thumbnail-overlay {
  display: none;
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 100;
  // top: calc(50% - 12px);
  // left: calc(50% - 12px);
  bottom: 10px;
  right: 10px;
}

.logo-thumbnail-wrap:hover .thumbnail-overlay {
    display: block;
}

.thumbnail-bin-wrap {
  position: absolute;
  width: 24px;
  height: 24px;
  z-index: 100;
  bottom: 10px;
  right: 10px;
}

.logo-thumbnail-wrap:hover .thumbnail-bin-wrap {
  display: none;
}

.image-thumbnail-list-wrap {
  position: relative;
  overflow: hidden;
  margin: 10px 0 0 0;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.attachment-loading-wrap {
  width: 130px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}



